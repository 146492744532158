@use '@on3/styles/base';

@use '@on3/styles/mixins' as mix;

@use '@on3/styles/variables' as var;

@use 'sass:color';

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  min-height: 100vh;
  background-image: url('https://on3static.com/cdn-cgi/image/quality=80/static/on3/404-background-mobile.png');
  background-position: center;
  background-size: cover;

  @media only screen and (min-width: 960px) {
    background-image: url('https://on3static.com/cdn-cgi/image/quality=80/static/on3/404-background-desktop.png');
  }
}

.header {
  @include mix.flex($justify: 'center');

  height: 50px;
  background: var.$neutralDark;

  @media only screen and (min-width: 960px) {
    height: 75px;
  }
}

.content {
  display: grid;
  place-items: center;
  padding: 4rem 0;

  @media only screen and (min-width: var.$mid-width) {
    padding: 8rem 0;
  }

  .container {
    display: grid;
    gap: 1.5rem;
    width: 100%;
    max-width: var.$mid-width;
    background-color: rgba(var.$black, 0.8);
    border-radius: 0;
    padding: 2rem;
    color: var.$white;

    @media screen and (min-width: var.$tablet-width) {
      padding: 4rem;
    }

    @media screen and (min-width: var.$mid-width) {
      border-radius: var.$radius-md;
    }
  }

  h1 {
    line-height: 1;
    font-size: 2.5rem;
    font-weight: 900;
    font-family: var.$geist;
  }

  p {
    font-size: 1.2rem;

    & + p {
      margin-top: 1rem;
    }
  }

  .button {
    @extend %btn-primary;

    background-color: var.$on3primary;

    &:hover {
      background-color: color.adjust($color: var.$on3primary, $lightness: -10%);
    }
  }
}

.footer {
  @media screen and (min-width: var.$mid-width) {
    padding: 2.5rem 0;
  }
}
